import React, { useEffect, useState, useRef } from 'react';
import { motion, useScroll, useTransform, MotionValue } from 'framer-motion';
import { FaPencilRuler, FaFlask, FaRocket } from 'react-icons/fa';

// Add at the top of the file, after imports
const DEBUG = false;  // Toggle this to show/hide debug information

// Throttle function to limit how often a function can be called
const throttle = (func: Function, limit: number) => {
  let inThrottle: boolean;
  return function(...args: any[]) {
    if (!inThrottle) {
      func.apply(this, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  }
};

// Helper component for scroll progress indicator
const ScrollProgressIndicator: React.FC<{ progress: number }> = ({ progress }) => {
  return (
    <div className="fixed top-4 right-4 bg-black/80 p-4 rounded-lg border border-[#00F135]/20 z-50 font-mono text-sm">
      <div className="text-[#00F135]">Scroll Progress</div>
      <div className="mt-2 w-48">
        <div className="h-2 bg-[#00F135]/20 rounded-full">
          <motion.div 
            className="h-full bg-[#00F135] rounded-full"
            style={{ width: `${progress}%` }}
          />
        </div>
        <div className="mt-1 text-white/60">
          {progress.toFixed(1)}%
        </div>
      </div>
    </div>
  );
};

// Helper component for each step in the pipeline
interface StepProps {
  title: string;
  description: string;
  icon: React.ReactNode;
  progress: number;
  threshold: [number, number];
  videoSrc?: string;
}

const PipelineStep: React.FC<StepProps> = ({ title, description, icon, progress, threshold, videoSrc }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  // Simplified transform calculations
  const getTransformValue = (
    progress: number,
    thresholds: [number, number],
    outputRange: [number, number, number, number]
  ) => {
    const [start, end] = thresholds;
    if (progress < start - 20) return outputRange[0];
    if (progress > end + 20) return outputRange[3];
    
    const progressInRange = (progress - start) / (end - start);
    if (progressInRange < 0.1) {
      return outputRange[0] + (outputRange[1] - outputRange[0]) * (progressInRange / 0.1);
    }
    if (progressInRange > 0.9) {
      return outputRange[2] + (outputRange[3] - outputRange[2]) * ((progressInRange - 0.9) / 0.1);
    }
    return outputRange[1];
  };

  const opacity = getTransformValue(
    progress,
    threshold,
    [0, 1, 1, 0]
  );

  const x = getTransformValue(
    progress,
    threshold,
    [100, 0, 0, -100]
  );

  // Ensure video plays immediately and continuously
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const ensurePlayback = () => {
      if (video.paused) {
        video.play().catch(() => {
          // If autoplay fails, retry after user interaction
          document.addEventListener('click', () => video.play(), { once: true });
        });
      }
    };

    video.addEventListener('loadeddata', ensurePlayback);
    video.addEventListener('pause', ensurePlayback);
    
    // Start playing immediately
    ensurePlayback();

    return () => {
      video.removeEventListener('loadeddata', ensurePlayback);
      video.removeEventListener('pause', ensurePlayback);
    };
  }, []);

  return (
    <div 
      className="absolute inset-0 flex items-center justify-center transform-gpu"
      style={{ 
        opacity,
        transform: `translate3d(${x}px, 0, 0)`,
        transition: `
          transform 0.8s cubic-bezier(0.4, 0, 0.2, 1),
          opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1)
        `,
        willChange: 'transform, opacity'
      }}
    >
      {/* Video background - always mounted */}
      <div 
        className="absolute inset-0 w-full h-full"
        style={{
          visibility: opacity > 0 ? 'visible' : 'hidden'
        }}
      >
        <div className="relative w-full h-full transform-gpu">
          <div className="absolute inset-0 overflow-hidden">
            <video
              ref={videoRef}
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              className="absolute w-full h-full object-cover"
              style={{
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                filter: 'brightness(0.45)',
                willChange: 'transform'
              }}
            >
              <source src={videoSrc} type="video/mp4" />
            </video>
          </div>
          {/* Single gradient overlay for better performance */}
          <div 
            className="absolute inset-0 bg-gradient-radial from-black/95 via-black/98 to-black"
            style={{ transform: 'translateZ(0)' }}
          />
        </div>
      </div>

      {/* Content */}
      <div className="relative z-10 max-w-4xl mx-auto text-center px-6">
        <div className="mb-12">
          <div className="relative w-20 h-20 mx-auto rounded-2xl bg-black/40 backdrop-blur-xl border border-[#00F135]/20 flex items-center justify-center mb-8">
            <div className="text-[#00F135] text-3xl">
              {icon}
            </div>
            <div className="absolute inset-0 rounded-2xl bg-[#00F135]/5 blur-xl opacity-50" />
          </div>
          
          <h3 
            className="text-5xl font-horizon text-white mb-8 tracking-wider"
            style={{
              textShadow: '0 0 30px rgba(0,241,53,0.2)'
            }}
          >
            {title}
          </h3>
        </div>

        <div className="relative">
          <p 
            className="text-2xl font-mono font-medium leading-relaxed max-w-2xl mx-auto"
            style={{
              color: '#00F135',
              textShadow: '0 2px 4px rgba(0,0,0,0.3)',
              WebkitFontSmoothing: 'antialiased'
            }}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

// Add this new component before the main DevelopmentPipelineSection component
interface ScrollMarkerProps {
  scrollProgress: number;
  threshold: [number, number];
  title: string;
}

const ScrollMarker: React.FC<ScrollMarkerProps> = ({ scrollProgress, threshold, title }) => {
  const getOpacity = (progress: number, [start, end]: [number, number]) => {
    if (progress < start) return 0.3;
    if (progress > end) return 0.3;
    return 1;
  };

  return (
    <motion.div
      className="flex items-center space-x-2"
      animate={{ opacity: getOpacity(scrollProgress, threshold) }}
      transition={{ duration: 0.2 }}
    >
      <div className="w-2 h-2 rounded-full bg-[#00F135]" />
      <div className="text-[#00F135] text-sm font-mono">{title}</div>
    </motion.div>
  );
};

export const DevelopmentPipelineSection: React.FC = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [debugInfo, setDebugInfo] = useState({
    containerTop: 0,
    windowHeight: 0,
    scrollY: 0,
    containerHeight: 0,
    viewportHeight: 0,
    containerRect: {
      top: 0,
      bottom: 0,
      height: 0
    }
  });

  // Track scroll position and update progress
  useEffect(() => {
    const updateScrollProgress = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const scrollContainer = containerRef.current.parentElement?.parentElement;
        const scrollTop = scrollContainer?.scrollTop || 0;
        const viewportHeight = window.innerHeight;
        
        // Calculate raw progress using original height
        const scrollRange = rect.height - viewportHeight;
        const rawProgress = (scrollTop / scrollRange) * 100;
        
        // Map progress from 20-150% range to 50-225% range
        let progress;
        if (rawProgress <= 20) {
          progress = 50;
        } else if (rawProgress >= 150) {
          progress = 225;
        } else {
          // Scale the intermediate progress with adjusted range
          const normalizedProgress = (rawProgress - 20) / 130; // Adjusted range (150-20=130)
          const scaledProgress = normalizedProgress * (175/130);
          progress = (scaledProgress * 175) + 50;
        }
        
        setScrollProgress(progress);
        
        setDebugInfo({
          containerTop: rect.top,
          windowHeight: viewportHeight,
          scrollY: scrollTop,
          containerHeight: rect.height,
          viewportHeight: viewportHeight,
          containerRect: {
            top: rect.top,
            bottom: rect.bottom,
            height: rect.height
          }
        });

        // Maintain negative margin throughout to keep hardware section pulled up
        containerRef.current.style.marginBottom = "-100vh";
      }
    };

    // Update immediately and then on scroll/resize
    updateScrollProgress();
    const scrollContainer = containerRef.current?.parentElement?.parentElement;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', updateScrollProgress, { passive: true });
      window.addEventListener('resize', updateScrollProgress, { passive: true });

      return () => {
        scrollContainer.removeEventListener('scroll', updateScrollProgress);
        window.removeEventListener('resize', updateScrollProgress);
      };
    }
  }, []);

  // Enhanced debug component with more information
  const DebugInfo: React.FC = () => (
    <div className="fixed bottom-4 left-4 bg-black/80 p-4 rounded-lg border border-[#00F135]/20 z-50 font-mono text-xs">
      <div className="text-[#00F135]">Enhanced Debug Info</div>
      <div className="text-white/60 space-y-1 mt-2">
        <div>Container Top: {debugInfo.containerRect.top.toFixed(0)}px</div>
        <div>Container Bottom: {debugInfo.containerRect.bottom.toFixed(0)}px</div>
        <div>Container Height: {debugInfo.containerRect.height.toFixed(0)}px</div>
        <div>Viewport Height: {debugInfo.viewportHeight}px</div>
        <div>Scroll Top: {debugInfo.scrollY}px</div>
        <div>Scroll Range: {(debugInfo.containerHeight - debugInfo.viewportHeight).toFixed(0)}px</div>
        <div className="text-[#00F135] mt-2">Scroll Progress:</div>
        <div>{((debugInfo.scrollY / Math.max(1, debugInfo.containerHeight - debugInfo.viewportHeight)) * 100).toFixed(1)}%</div>
      </div>
    </div>
  );

  // Define our pipeline steps with adjusted thresholds
  const steps = [
    {
      title: "DESIGN",
      description: "Create intelligent behaviors with intuitive visual programming",
      icon: <FaPencilRuler className="transform-gpu" />,
      threshold: [75, 125] as [number, number],
      videoSrc: "/easy_to_program.mp4"
    },
    {
      title: "TEST",
      description: "Validate performance in our high-fidelity simulation environment",
      icon: <FaFlask className="transform-gpu" />,
      threshold: [125, 175] as [number, number],
      videoSrc: "/custom_maps.mp4"
    },
    {
      title: "DEPLOY",
      description: "Seamlessly transition from simulation to real-world flight",
      icon: <FaRocket className="transform-gpu" />,
      threshold: [175, 225] as [number, number],
      videoSrc: "/dev4.mp4"
    }
  ];

  // Title opacity calculation adjusted for new range
  const getTitleOpacity = (progress: number) => {
    if (progress <= 50) return 1;
    if (progress >= 70) return 0;
    return 1 - ((progress - 50) / 20);
  };

  // Preload all videos at component mount
  React.useEffect(() => {
    const videos = steps.map(step => {
      const video = document.createElement('video');
      video.src = step.videoSrc || '';
      video.preload = "auto";
      return video;
    });

    return () => {
      videos.forEach(video => {
        video.remove();
      });
    };
  }, []);

  return (
    <div 
      ref={containerRef} 
      className="relative w-full"
      style={{ 
        height: "600vh",
        contain: "size layout style paint",
        willChange: "transform",
        marginBottom: "-100vh"
      }}
    >
      {/* Sticky container */}
      <div 
        className="sticky top-0 left-0 right-0 w-full h-screen overflow-hidden"
        style={{
          willChange: "transform",
          transform: "translateZ(0)"
        }}
      >
        {/* Debug components - only shown when DEBUG is true */}
        {DEBUG && (
          <>
            <ScrollProgressIndicator progress={scrollProgress} />
            <DebugInfo />
          </>
        )}

        {/* Background effects */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-b from-black via-black/95 to-black" />
          <motion.div 
            className="absolute inset-0 tech-grid opacity-10"
            animate={{
              opacity: [0.1, 0.15, 0.1],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />
          <motion.div 
            className="absolute inset-0 hero-gradient opacity-20"
            animate={{
              opacity: [0.2, 0.25, 0.2],
            }}
            transition={{
              duration: 6,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />
        </div>

        {/* Title that fades out */}
        <motion.div 
          className="absolute inset-0 flex items-center justify-center"
          animate={{ opacity: getTitleOpacity(scrollProgress) }}
          transition={{ duration: 0.2 }}
        >
          <div className="text-center">
            <h2 className="text-2xl font-mono text-[#00F135] mb-3 tracking-wider">
              Droneforge Introduces...
            </h2>
            <h3 className="text-6xl text-white mb-6 font-horizon tracking-tight">
              Agent Development
            </h3>
            <p className="text-lg text-white/60 font-mono max-w-2xl mx-auto">
              From concept to deployment, we've streamlined the journey
            </p>
          </div>
        </motion.div>

        {/* Pipeline steps */}
        {steps.map((step, index) => (
          <PipelineStep
            key={index}
            {...step}
            progress={scrollProgress}
          />
        ))}

        {/* Visual scroll markers - adjusted for extended range */}
        <div className="absolute right-8 h-screen top-0 w-1 bg-[#00F135]/10">
          {[75, 125, 175, 225].map((threshold, i) => (
            <div
              key={i}
              className="absolute w-2 h-2 -left-[3px] bg-[#00F135] rounded-full"
              style={{
                top: `${((threshold - 50) / 175) * 100}%`,
              }}
            />
          ))}
        </div>

        {/* Scroll markers - now inside sticky container */}
        <div className="absolute left-4 top-1/2 transform -translate-y-1/2 space-y-4">
          {steps.map((step, index) => (
            <ScrollMarker
              key={index}
              scrollProgress={scrollProgress}
              threshold={[step.threshold[0], step.threshold[1]]}
              title={step.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
}; 