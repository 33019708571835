import React, { useState, useEffect, useCallback } from 'react';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import { Logo3D } from '../Logo3D.tsx';
import { FiCode, FiBox, FiCpu, FiUploadCloud, FiTarget, FiAward } from 'react-icons/fi';
import { DroneforgeButton } from '../DroneforgeButton.tsx';
import { FiTwitter, FiMessageCircle, FiMail } from 'react-icons/fi';
import { FaDiscord } from 'react-icons/fa';
import { FiArrowRight } from 'react-icons/fi';
import { HiRocketLaunch } from 'react-icons/hi2';
import { Object3D, Mesh, Material } from 'three';

// Move DRONE_MODEL_PATH to top and ensure immediate preload
const DRONE_MODEL_PATH = '/droneforge_dronealpha.glb';

// Preload immediately and handle errors
try {
  useGLTF.preload(DRONE_MODEL_PATH);
} catch (error) {
  console.error('Failed to preload drone model:', error);
}

// Add error boundary component
const ModelErrorBoundary: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [hasError, setHasError] = React.useState(false);

  if (hasError) {
    return null;
  }

  return (
    <React.Suspense fallback={null}>
      {children}
    </React.Suspense>
  );
};

const DroneMesh = ({ isMobile }: { isMobile: boolean }) => {
  const { scene } = useGLTF(DRONE_MODEL_PATH);
  const [scale, setScale] = React.useState(0.007);
  const [yPosition, setYPosition] = React.useState(0);
  
  // Cleanup function to dispose of the model
  React.useEffect(() => {
    return () => {
      if (scene) {
        scene.traverse((object: Object3D) => {
          if (object instanceof Mesh) {
            if (object.geometry) object.geometry.dispose();
            if (object.material) {
              if (Array.isArray(object.material)) {
                object.material.forEach((material: Material) => material.dispose());
              } else {
                object.material.dispose();
              }
            }
          }
        });
      }
    };
  }, [scene]);

  // Calculate values based on screen size
  const calculateValues = React.useCallback(() => {
    const width = window.innerWidth;
    const isTablet = width >= 640 && width < 1024;
    const isIntermediate = width >= 1024 && width < 1280;
    const isDesktop = width >= 1280;

    let newScale = 0.007; // default scale
    if (isMobile) newScale = 0.006;
    if (isTablet) newScale = 0.005;
    if (isIntermediate) newScale = 0.0058;

    let newPosition = 0;
    if (isIntermediate) newPosition = 0.08;
    if (isDesktop) newPosition = 0.05;
    if (isTablet) newPosition = 0.10;

    return { scale: newScale, yPosition: newPosition };
  }, [isMobile]);

  // Handle window resize
  React.useEffect(() => {
    const handleResize = () => {
      const { scale: newScale, yPosition: newPosition } = calculateValues();
      setScale(newScale);
      setYPosition(newPosition);
    };

    // Initial calculation
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [calculateValues]);

  return (
    <primitive 
      object={scene} 
      scale={scale}
      position={[0, yPosition, 0]}
      rotation={[0.2, 0, 0]} 
    />
  );
};

export const FeatureCard: React.FC<{
  title: string;
  description: string;
  icon: React.ReactNode;
  delay: number;
}> = ({ title, description, icon, delay }) => {
  const [spinCount, setSpinCount] = React.useState(0);
  const [isMobile, setIsMobile] = React.useState(false);

  React.useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 640);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const handleCardClick = () => {
    setSpinCount(prev => prev + 1);
  };

  return (
    <motion.div
      className={`relative perspective-1500 cursor-pointer w-full ${isMobile ? 'mobile-card rounded-2xl' : ''}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
      onClick={handleCardClick}
    >
      <motion.div
        className="relative h-full"
        whileHover={isMobile ? {
          scale: 1.02,
          transition: { duration: 0.3, ease: "easeOut" }
        } : {
          rotateX: "-10deg",
          rotateY: "-15deg",
          transition: { duration: 0.4, ease: "easeOut" }
        }}
        whileTap={{ scale: 0.98 }}
        style={{
          transformStyle: "preserve-3d",
          transformOrigin: "center center"
        }}
      >
        <div className={`
          relative h-full glass-panel 
          ${isMobile ? 'p-3 rounded-lg' : 'p-4 sm:p-6 rounded-xl'} 
          border border-[#00F135]/10 
          hover:border-[#00F135]/30 
          transition-all duration-300 
          group backdrop-blur-sm
        `}>
          {/* Refined Shimmer Effect - Only on Desktop */}
          {!isMobile && (
            <div className="absolute inset-0 rounded-xl overflow-hidden">
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                <motion.div 
                  className="absolute inset-0"
                  initial={{ x: '-100%' }}
                  animate={{ x: '100%' }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    ease: "linear",
                    repeatDelay: 1
                  }}
                  style={{
                    background: 'linear-gradient(90deg, transparent 0%, rgba(0,241,53,0.05) 45%, rgba(0,241,53,0.1) 50%, rgba(0,241,53,0.05) 55%, transparent 100%)',
                    width: '100%',
                    height: '100%'
                  }}
                />
              </div>
            </div>
          )}

          {/* Enhanced 3D Shadow Effect - Only on Desktop */}
          {!isMobile && (
            <div 
              className="absolute inset-0 rounded-xl opacity-0 group-hover:opacity-100 transition-all duration-500"
              style={{
                background: 'linear-gradient(225deg, transparent 50%, rgba(0,241,53,0.1) 100%)',
                transform: 'translateZ(-40px)',
                pointerEvents: 'none'
              }}
            />
          )}

          <div className="relative z-10 space-y-2">
            <div className="flex items-center justify-between gap-2">
              <motion.h3 
                className={`text-white font-horizon ${isMobile ? 'text-base' : 'text-lg sm:text-xl'}`}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3, delay: delay + 0.2 }}
              >
                {title}
              </motion.h3>

              <motion.div
                className={`
                  relative rounded-lg bg-[#00F135]/10 
                  flex items-center justify-center
                  ${isMobile ? 'w-8 h-8' : 'w-10 h-10 sm:w-12 sm:h-12 group-hover:scale-110'}
                  transition-transform duration-300
                `}
                animate={{ rotate: spinCount * 360 }}
                transition={{ duration: 0.7 }}
              >
                <div className={`text-[#00F135] ${isMobile ? 'text-lg' : 'text-xl sm:text-2xl'}`}>
                  {icon}
                </div>
                {!isMobile && (
                  <div className="absolute inset-0 rounded-lg bg-[#00F135]/20 blur-md opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                )}
              </motion.div>
            </div>

            <motion.p 
              className={`text-white/60 ${isMobile ? 'text-xs' : 'text-xs sm:text-sm'} text-left leading-snug`}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: delay + 0.3 }}
            >
              {description}
            </motion.p>
          </div>

          {/* Hover Glow Effect - Only on Desktop */}
          {!isMobile && (
            <div className="absolute inset-0 rounded-xl opacity-0 group-hover:opacity-100 transition-all duration-500">
              <div className="absolute inset-0 bg-gradient-to-bl from-transparent to-[#00F135]/5 rounded-xl" />
              <div className="absolute inset-0 bg-[#00F135]/5 blur-xl" />
            </div>
          )}
        </div>

        {/* 3D Lift Effect - Only on Desktop */}
        {!isMobile && (
          <div 
            className="absolute inset-0 rounded-xl bg-[#00F135]/5"
            style={{
              transform: 'translateZ(-40px)',
              filter: 'blur(15px)',
              opacity: 0,
              transition: 'opacity 0.3s ease'
            }}
          />
        )}
      </motion.div>
    </motion.div>
  );
};

export const MobileFeatureCard: React.FC<{
  title: string;
  description: string;
  icon: React.ReactNode;
  delay: number;
}> = ({ title, description, icon, delay }) => {
  const [spinCount, setSpinCount] = React.useState(0);

  const handleCardClick = () => {
    setSpinCount(prev => prev + 1);
  };

  return (
    <motion.div
      className="relative perspective-1500 cursor-pointer w-full"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
      onClick={handleCardClick}
    >
      <motion.div
        className="relative h-full"
        whileHover={{
          rotateX: "-10deg",
          rotateY: "-15deg",
          transition: { duration: 0.4, ease: "easeOut" }
        }}
        whileTap={{ scale: 0.98 }}
        style={{
          transformStyle: "preserve-3d",
          transformOrigin: "center center"
        }}
      >
        <div className="relative h-full glass-panel p-3.5 rounded-xl border border-[#00F135]/10 hover:border-[#00F135]/30 transition-all duration-300 group backdrop-blur-sm">
          <div className="relative z-10">
            <div className="flex items-center gap-3">
              <motion.div
                className="relative w-9 h-9 rounded-lg bg-[#00F135]/10 flex items-center justify-center group-hover:scale-110 transition-transform duration-300"
                animate={{ rotate: spinCount * 360 }}
                transition={{ duration: 0.7 }}
              >
                <div className="text-[#00F135] text-lg">
                  {icon}
                </div>
                <div className="absolute inset-0 rounded-lg bg-[#00F135]/20 blur-md opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </motion.div>

              <motion.h3 
                className="text-white font-horizon text-base"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3, delay: delay + 0.2 }}
              >
                {title}
              </motion.h3>
            </div>

            <motion.p 
              className="text-white/60 text-xs text-left leading-relaxed mt-4 pl-12"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: delay + 0.3 }}
            >
              {description}
            </motion.p>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

const MobileFeatureFlow: React.FC<{ delay: number }> = ({ delay }) => {
  return (
    <motion.div
      className="flex items-center justify-between w-full px-2"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay }}
    >
      <motion.div
        className="flex items-center"
        initial={{ x: -20 }}
        animate={{ x: 0 }}
        transition={{ duration: 0.5, delay }}
      >
        <div className="text-[#00F135] text-sm font-horizon">BUILD</div>
        <FiArrowRight className="text-[#00F135]/50 mx-3" />
      </motion.div>

      <motion.div
        className="flex items-center"
        initial={{ x: -10 }}
        animate={{ x: 0 }}
        transition={{ duration: 0.5, delay: delay + 0.1 }}
      >
        <div className="text-[#00F135] text-sm font-horizon">TEST</div>
        <FiArrowRight className="text-[#00F135]/50 mx-3" />
      </motion.div>

      <motion.div
        initial={{ x: -20 }}
        animate={{ x: 0 }}
        transition={{ duration: 0.5, delay: delay + 0.2 }}
      >
        <div className="text-[#00F135] text-sm font-horizon">DEPLOY</div>
      </motion.div>
    </motion.div>
  );
};

const FeatureStep: React.FC<{
  text: string;
  delay: number;
  isLast?: boolean;
}> = ({ text, delay, isLast }) => (
  <motion.div
    className="flex items-center"
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay }}
  >
    <div className="relative">
      <div className="absolute inset-0 bg-[#00F135]/5 blur-lg rounded-lg" />
      <div className="relative px-3 py-1.5 rounded-lg bg-black/20 backdrop-blur-sm border border-[#00F135]/10">
        <span className="text-white/90 text-xs font-horizon">{text}</span>
      </div>
    </div>
    {!isLast && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: delay + 0.1 }}
        className="mx-2"
      >
        <FiArrowRight className="text-[#00F135]/40 text-xs" />
      </motion.div>
    )}
  </motion.div>
);

// Update Canvas wrapper to use error boundary
const DroneCanvas = ({ isMobile }) => (
  <Canvas
    camera={{ 
      position: [0, 0.5, 3],
      fov: 35,
      near: 0.1,
      far: 1000
    }}
    style={{ 
      background: 'transparent',
      width: '100%',
      height: '100%'
    }}
  >
    <ModelErrorBoundary>
      <ambientLight intensity={0.3} />
      <pointLight position={[10, 10, 10]} intensity={1000} color="#FFFFFF" />
      <pointLight position={[-10, 5, -10]} intensity={1000} color="#00F135" />
      <pointLight position={[0, 10, -15]} intensity={0.6} color="#7928CA" />
      <pointLight position={[0, -5, 5]} intensity={0.6} color="#00F135" />
      <DroneMesh isMobile={isMobile} />
      <OrbitControls
        enableZoom={false}
        enablePan={false}
        autoRotate
        autoRotateSpeed={0.5}
        maxPolarAngle={Math.PI / 2}
        minPolarAngle={Math.PI / 2}
      />
    </ModelErrorBoundary>
  </Canvas>
);

export const HeroSection: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [showContent, setShowContent] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 768);
  const [screenSize, setScreenSize] = React.useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setScreenSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getTitleSize = () => {
    if (screenSize < 640) return 'text-5xl';
    if (screenSize < 768) return 'text-[7rem]';  // iPad
    if (screenSize < 1024) return 'text-[7rem]'; // iPad Pro
    if (screenSize < 1280) return 'text-[9.5rem]';  // Intermediate size
    return 'text-[12rem]';
  };

  const getSubtitleSize = () => {
    if (screenSize < 640) return 'text-xl';
    if (screenSize < 768) return 'text-2xl';
    if (screenSize < 1024) return 'text-3xl';
    return 'text-4xl';
  };

  React.useEffect(() => {
    const loadingTimer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    const contentTimer = setTimeout(() => {
      setShowContent(true);
    }, 3500);

    return () => {
      clearTimeout(loadingTimer);
      clearTimeout(contentTimer);
    };
  }, []);

  const handleContactClick = () => {
    window.location.href = 'mailto:chesterzelaya@berkeley.edu';
  };

  if (isMobile) {
    return (
      <section className="relative w-full min-h-screen bg-black overflow-hidden">
        {/* Background Effects */}
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-radial from-black via-black to-[#001]" />
          <div className="absolute inset-0 hero-gradient opacity-50" />
        </div>

        {/* Loading Animation */}
        {!showContent && (
          <motion.div 
            className="absolute w-[250px] h-[250px] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            initial={{ opacity: 1 }}
            animate={{ opacity: isLoading ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          >
            <Canvas camera={{ position: [0, 0, 10], fov: 45 }} style={{ background: 'transparent' }}>
              <React.Suspense fallback={null}>
                <ambientLight intensity={0.3} />
                <pointLight position={[10, 10, 10]} intensity={0.7} color="#00F135" />
                <Logo3D />
                <OrbitControls enableZoom={false} enablePan={false} autoRotate autoRotateSpeed={2} />
              </React.Suspense>
            </Canvas>
          </motion.div>
        )}

        {showContent && (
          <div className="relative z-10 min-h-screen flex flex-col">
            {/* Main Content */}
            <div className="flex-1 flex flex-col items-center justify-start px-4 pt-16 pb-6">
              {/* Title and Subtitle */}
              <motion.div
                className="w-full space-y-4"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                {/* Title */}
                <div className="text-center mt-8 mb-6">
                  <motion.h1 
                    className="font-horizon tracking-tight text-white leading-none"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.2 }}
                  >
                    <div className={`${getTitleSize()} flex flex-col items-center leading-none`}>
                      <span className="mb-4">DRONE</span>
                      <motion.span 
                        className="relative"
                        animate={{ 
                          textShadow: [
                            "0 0 20px rgba(0,241,53,0)",
                            "0 0 30px rgba(0,241,53,0.2)",
                            "0 0 20px rgba(0,241,53,0)"
                          ]
                        }}
                        transition={{
                          duration: 3,
                          repeat: Infinity,
                          ease: "easeInOut"
                        }}
                      >
                        <span className="text-[#00F135]">
                          AGENTS
                        </span>
                      </motion.span>
                    </div>
                  </motion.h1>
                </div>

                {/* Icon Flow Section */}
                <motion.div
                  className="relative flex flex-row items-center justify-between w-full max-w-[360px] mx-auto mt-4 mb-4"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4 }}
                >
                  {/* Vertical Lines - Only visible on mobile */}
                  <div className="absolute left-[30%] top-1/2 -translate-y-1/2 w-[1px] h-[30px] bg-[#00F135]/20" />
                  <div className="absolute right-[30%] top-1/2 -translate-y-1/2 w-[1px] h-[30px] bg-[#00F135]/20" />

                  <div className="flex flex-col items-center gap-2">
                    <FiCode className="text-[#00F135] text-2xl" />
                    <span className="text-white/80 text-sm font-horizon">DEVELOP</span>
                  </div>
                  <div className="flex flex-col items-center gap-2">
                    <HiRocketLaunch className="text-[#00F135] text-2xl" />
                    <span className="text-white/80 text-sm font-horizon">DEPLOY</span>
                  </div>
                  <div className="flex flex-col items-center gap-2">
                    <FiAward className="text-[#00F135] text-2xl" />
                    <span className="text-white/80 text-sm font-horizon">ACHIEVE</span>
                  </div>
                </motion.div>

                {/* 3D Drone Model */}
                <div className="w-full flex items-center justify-center pointer-events-none mt-2">
                  <div className="w-full aspect-[16/9] max-h-[25vh]">
                    <DroneCanvas isMobile={isMobile} />
                  </div>
                </div>

                {/* Description */}
                <motion.div
                  className="text-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4 }}
                >
                  <p className="text-white/60 mb-4 text-sm font-mono leading-relaxed max-w-md mx-auto">
                    Any business, any industry, any location, real actionable results.
                  </p>
                </motion.div>

                {/* CTA Section - Moved up */}
                <motion.div 
                  className="w-full flex flex-col items-center space-y-4 mt-8"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.5 }}
                >
                  <div className="w-full flex justify-center">
                    <DroneforgeButton
                      variant="accent"
                      size="lg"
                      className="w-[300px] rounded-full group overflow-visible backdrop-blur-sm touch-manipulation relative animate-pulse-slow text-center"
                      href="/simulation"
                    >
                      <span className="relative z-10 flex items-center justify-center w-full gap-1.5 sm:gap-2">
                        <span style={{ 
                          textShadow: "0 0 20px rgba(0,241,53,0.8), 0 0 40px rgba(0,241,53,0.6)",
                        }}>
                          START BUILDING NOW
                        </span>
                      </span>
                    </DroneforgeButton>
                  </div>

                  <div className="flex justify-center gap-3">
                    <DroneforgeButton href="https://discord.gg/xFh3xxfyUh" variant="primary" size="sm">
                      <FaDiscord />
                      <span>Discord</span>
                    </DroneforgeButton>

                    <DroneforgeButton href="https://twitter.com/thedroneforge" variant="primary" size="sm">
                      <FiTwitter />
                      <span>X</span>
                    </DroneforgeButton>

                    <DroneforgeButton variant="primary" size="sm" onClick={handleContactClick}>
                      <FiMail />
                      <span>Contact</span>
                    </DroneforgeButton>
                  </div>
                </motion.div>
              </motion.div>
            </div>
          </div>
        )}
      </section>
    );
  }

  // Desktop version
  return (
    <section className="relative w-full min-h-screen flex items-center justify-center bg-black overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-radial from-black via-black to-[#001]" />
        <div className="absolute inset-0 hero-gradient opacity-50" />
      </div>

      {/* Loading Animation */}
      {!showContent && (
        <motion.div 
          className="absolute w-[600px] h-[600px]"
          initial={{ opacity: 1 }}
          animate={{ opacity: isLoading ? 1 : 0 }}
          transition={{ duration: 0.5 }}
        >
          <Canvas camera={{ position: [0, 0, 10], fov: 45 }} style={{ background: 'transparent' }}>
            <React.Suspense fallback={null}>
              <ambientLight intensity={0.3} />
              <pointLight position={[10, 10, 10]} intensity={0.7} color="#00F135" />
              <Logo3D />
              <OrbitControls enableZoom={false} enablePan={false} autoRotate autoRotateSpeed={2} />
            </React.Suspense>
          </Canvas>
        </motion.div>
      )}

      {showContent && (
        <div className="relative z-10 w-full max-w-[2000px] mx-auto px-8">
          <div className={`relative min-h-screen flex flex-col ${screenSize >= 640 && screenSize < 1024 ? 'justify-start gap-8' : 'py-32 justify-between'}`}>
            {/* Title Section */}
            <div className={`flex items-center justify-center ${screenSize >= 640 && screenSize < 1024 ? 'pt-48' : 'flex-1'}`}>
              {/* 3D Drone Model */}
              <div className="absolute inset-0 flex items-center justify-center pointer-events-none overflow-visible" style={{ zIndex: 20, transform: 'translateY(-2rem)' }}>
                <div className="w-full aspect-[16/9] max-h-[50vh] relative">
                  <DroneCanvas isMobile={isMobile} />
                </div>
              </div>

              {/* Title Content */}
              <motion.div
                className="w-full text-center relative"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                style={{ zIndex: 0, transform: 'translateY(3rem)' }}
              >
                <motion.h1 
                  className="font-horizon tracking-tight text-white leading-none"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                >
                  <div className={`${getTitleSize()} flex flex-col items-center leading-none`}>
                    <span className={`${isMobile ? 'mb-8' : 'pt-8 mb-24'}`}>DRONE</span>
                    <motion.span 
                      className="relative"
                      animate={{ 
                        textShadow: [
                          "0 0 20px rgba(0,241,53,0)",
                          "0 0 30px rgba(0,241,53,0.2)",
                          "0 0 20px rgba(0,241,53,0)"
                        ]
                      }}
                      transition={{
                        duration: 3,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                    >
                      <span className="text-[#00F135]">
                        AGENTS
                      </span>
                    </motion.span>
                  </div>
                  <motion.div
                    className="relative flex flex-row items-center justify-between w-full max-w-[800px] mx-auto mt-8 mb-8"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.4 }}
                  >
                    <div className="flex flex-col items-center gap-3">
                      <FiCode className="text-[#00F135] text-3xl" />
                      <span className="text-white/80 text-2xl font-horizon">DEVELOP</span>
                    </div>
                    <div className="absolute left-1/2 -translate-x-1/2 flex flex-col items-center gap-3">
                      <HiRocketLaunch className="text-[#00F135] text-3xl" />
                      <span className="text-white/80 text-2xl font-horizon">DEPLOY</span>
                    </div>
                    <div className="flex flex-col items-center gap-3">
                      <FiAward className="text-[#00F135] text-3xl" />
                      <span className="text-white/80 text-2xl font-horizon">ACHIEVE</span>
                    </div>
                  </motion.div>
                </motion.h1>
              </motion.div>
            </div>

            {/* Bottom Content Section */}
            <div className="w-full flex flex-col items-center justify-center mt-8">
              <DroneforgeButton
                variant="accent"
                size="lg"
                className="w-[300px] rounded-full group overflow-visible backdrop-blur-sm touch-manipulation relative animate-pulse-slow text-center"
                href="/simulation"
              >
                {/* Button content */}
                <span className="relative z-10 flex items-center justify-center w-full gap-1.5 sm:gap-2">
                  <span style={{ 
                    textShadow: "0 0 20px rgba(0,241,53,0.8), 0 0 40px rgba(0,241,53,0.6)",
                  }}>
                    START BUILDING NOW
                  </span>
                </span>
              </DroneforgeButton>

              <style>
                {`
                  @keyframes pulse-slow {
                    0%, 100% {
                      transform: scale(1);
                    }
                    50% {
                      transform: scale(1.05);
                    }
                  }

                  .animate-pulse-slow {
                    animation: pulse-slow 2s infinite;
                  }
                `}
              </style>

              <div className="flex gap-3 mt-8">
                <DroneforgeButton href="https://discord.gg/xFh3xxfyUh" variant="primary" size="md">
                  <FaDiscord />
                  <span>Discord</span>
                </DroneforgeButton>

                <DroneforgeButton href="https://twitter.com/thedroneforge" variant="primary" size="md">
                  <FiTwitter />
                  <span>X</span>
                </DroneforgeButton>

                <DroneforgeButton variant="primary" size="md" onClick={handleContactClick}>
                  <FiMail />
                  <span>Contact</span>
                </DroneforgeButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}; 