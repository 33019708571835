import React from 'react';
import { Navbar } from './Navbar.tsx';

interface ChangelogEntry {
  date: string;
  version: string;
  title: string;
  description: string;
  type: 'feature' | 'improvement' | 'fix' | 'alpha launch' | 'feature addition';
}

const changelogData: ChangelogEntry[] = [
  {
    date: '2024-1-8',
    version: 'v0.1.1',
    title: 'Access to MobilenetV2 and Yolov10n',
    description: 'Update that better supports the use of MobilenetV2 and Yolov10n for computer vision tasks. Added Sommerset Map of New Jersey and UFO game for testing. General improvements in speed, memory usage, and overall performance. ',
    type: 'feature addition'
  },
  {
    date: '2024-12-12',
    version: 'v0.1.0',
    title: 'Launch of DroneForge Alpha',
    description: 'Initial release of DroneForge platform. Features include a simulation environment, optimized physics, controller support, customizable maps, built in computer vision support, and more!',
    type: 'alpha launch'
  }  
];

const Changelog: React.FC = () => {
  return (
    <div className="relative min-h-screen bg-black">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-radial from-black via-black to-[#001]" />
        <div className="absolute inset-0 hero-gradient opacity-50" />
        <div className="absolute top-[15%] left-[25%] w-[50%] h-[50%] bg-[#00F135]/[0.03] blur-[150px] rounded-full" />
      </div>

      {/* Navbar */}
      <Navbar show={true} />

      {/* Content */}
      <div className="relative z-10 container mx-auto px-4 pt-24 pb-16">
        <h1 className="font-horizon text-4xl sm:text-5xl text-white text-center mb-16">
          CHANGELOG
        </h1>

        <div className="max-w-4xl mx-auto">
          {changelogData.map((entry, index) => (
            <div key={index} className="relative pl-8 pb-12 flex">
              {/* Left side: Date and Version */}
              <div className="w-48 pr-8 pt-6 flex flex-row items-center justify-end gap-2">
                <span className="text-white/60 font-jetbrains text-sm">
                  {entry.date}
                </span>
                <span className="text-[#00F135] font-jetbrains text-sm font-bold">
                  {entry.version}
                </span>
              </div>

              {/* Timeline elements */}
              <div className="relative">
                {/* Unified Vertical Line - only show if not the last item */}
                {index < changelogData.length - 1 && (
                  <div className="absolute left-0 top-6 h-full w-px bg-[#00F135]/30" />
                )}

                {/* Timeline Dot with pulse effect - only show for first (latest) entry */}
                {index === 0 && (
                  <div className="absolute left-[-4px] top-6">
                    <div className="w-2 h-2 rounded-full bg-[#00F135] shadow-[0_0_8px_#00F135] relative">
                      <div className="absolute inset-0 rounded-full bg-[#00F135] animate-ping opacity-20" />
                    </div>
                  </div>
                )}
                {/* Regular dot for other entries */}
                {index > 0 && (
                  <div className="absolute left-[-3px] top-6 w-1.5 h-1.5 rounded-full bg-[#00F135]/30" />
                )}

                {/* Content Card */}
                <div className="bg-[#00F135]/[0.03] backdrop-blur-sm rounded-lg p-6 ml-6 hover:bg-[#00F135]/[0.05] transition-all duration-300 border border-[#00F135]/10">
                  <div className="flex items-center justify-between mb-2">
                    <h3 className="text-white font-jetbrains text-xl">{entry.title}</h3>
                    <span className={`px-3 py-1 rounded-full text-xs font-medium ${
                      entry.type === 'feature' ? 'bg-blue-500/20 text-blue-400' :
                      entry.type === 'improvement' ? 'bg-purple-500/20 text-purple-400' :
                      entry.type === 'alpha launch' ? 'bg-[#00F135]/20 text-[#00F135]' :
                      entry.type === 'feature addition' ? 'bg-amber-500/20 text-amber-400' :
                      'bg-green-500/20 text-green-400'
                    }`}>
                      {entry.type}
                    </span>
                  </div>
                  <p className="text-white/80 font-jetbrains leading-relaxed">
                    {entry.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Changelog; 