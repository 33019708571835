"use client"
import React from 'react';
import { HeroSection } from './sections/HeroSection.tsx';
import { SimulationShowcaseSection } from './sections/SimulationShowcaseSection.tsx';
import { DevelopmentPipelineSection } from './sections/DevelopmentPipelineSection.tsx';
import { HubSection } from './sections/HubSection.tsx';
import { Navbar } from './Navbar.tsx';
import './styles/globals.css';

const Landing_Bryce: React.FC = () => {
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 640;
  const [showContent, setShowContent] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 3500);

    return () => clearTimeout(timer);
  }, []);

  if (isMobile) {
    return (
      <>
        <HeroSection />
      </>
    );
  }

  return (
    <div className="relative w-full">
      <Navbar show={showContent} />
      <div 
        className="relative w-full"
        style={{
          height: "100vh",
          overflowY: "auto",
          overflowX: "hidden"
        }}
      >
        <div className="relative w-full min-h-screen">
          <HeroSection />
        </div>
        <div className="relative w-full">
          <DevelopmentPipelineSection />
        </div>
        {/* <div className="relative w-full">
          <SimulationShowcaseSection />
        </div> */}
        <div className="relative w-full">
          <HubSection />
        </div>
      </div>
    </div>
  );
};

export default Landing_Bryce;