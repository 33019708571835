import React from 'react';
import { motion } from 'framer-motion';
import { FaXTwitter } from 'react-icons/fa6';
import { FaDiscord, FaShoppingCart, FaRobot, FaGraduationCap, FaHeart } from 'react-icons/fa';
import { DroneforgeButton } from '../DroneforgeButton.tsx';

interface HubCardProps {
  title: string;
  description: string;
  color: string;
  icon: React.ReactNode;
  link: string;
}

const HubCard: React.FC<HubCardProps> = ({ title, description, color, icon, link }) => {
  const [isFlipped, setIsFlipped] = React.useState(false);
  const isShopCard = title === "Shop Drone";

  const handleClick = (e: React.MouseEvent) => {
    if (isShopCard) {
      e.preventDefault();
      setIsFlipped(!isFlipped);
    }
  };

  return (
    <motion.a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="relative group perspective-1000"
      onClick={handleClick}
      whileHover={{ 
        scale: 1.02,
        rotateX: "10deg",
        rotateY: isShopCard ? "0deg" : "-5deg",
        y: -8,
        transition: { 
          duration: 0.3,
          ease: [0.2, 0.8, 0.2, 1]
        }
      }}
      whileTap={{ scale: 0.98 }}
    >
      <div 
        className="relative w-full h-64 transition-all duration-500 ease-out transform-gpu preserve-3d"
        style={{ 
          transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
          transformStyle: 'preserve-3d'
        }}
      >
        {/* Front of card */}
        <div 
          className="absolute inset-0 glass-panel p-6 rounded-xl border-2 overflow-hidden transition-all duration-400 ease-out backface-hidden backdrop-blur-md"
          style={{ 
            borderColor: `${color}50`,
            boxShadow: `
              0 0 25px ${color}30,
              inset 0 0 30px ${color}20
            `,
            background: `
              linear-gradient(135deg, 
                ${color}15 0%, 
                ${color}08 50%,
                ${color}12 100%
              )
            `,
            transition: "all 0.4s cubic-bezier(0.2, 0.8, 0.2, 1)",
            transform: "translateZ(0)",
            backfaceVisibility: 'hidden'
          }}
        >
          {/* Tech grid background */}
          <div 
            className="absolute inset-0 transition-all duration-500 ease-out opacity-15"
            style={{
              backgroundImage: `
                linear-gradient(to right, ${color}20 1px, transparent 1px),
                linear-gradient(to bottom, ${color}20 1px, transparent 1px)
              `,
              backgroundSize: '20px 20px',
              mask: 'radial-gradient(circle at center, black 60%, transparent)'
            }}
          />

          {/* Glowing edge effect */}
          <div 
            className="absolute inset-0 transition-all duration-500 ease-out"
            style={{
              background: `
                linear-gradient(125deg, 
                  ${color}20 0%, 
                  transparent 70%
                )
              `,
              opacity: 0.5,
              filter: 'blur(20px)'
            }}
          />

          {/* Content with enhanced glow */}
          <div className="relative z-10 h-full flex flex-col items-center justify-center text-center space-y-4">
            <div 
              className="text-4xl transform transition-all duration-500 ease-out group-hover:scale-110 group-hover:-translate-y-2"
              style={{ 
                color: color,
                filter: `drop-shadow(0 0 15px ${color}50)`,
                opacity: 0.95
              }}
            >
              {icon}
            </div>
            <h3 
              className="text-2xl font-horizon tracking-wider transition-all duration-500 ease-out group-hover:-translate-y-1"
              style={{ 
                color: color,
                filter: `drop-shadow(0 0 12px ${color}40)`,
                opacity: 0.9
              }}
            >
              {title}
            </h3>
            <p className="text-white/60 text-sm transition-all duration-500 ease-out group-hover:-translate-y-1">
              {description}
            </p>
          </div>

          {/* Enhanced hover glow */}
          <div 
            className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-all duration-500 ease-out"
            style={{
              background: `
                radial-gradient(circle at center,
                  ${color}15 0%,
                  ${color}08 30%,
                  transparent 70%
                )
              `,
              filter: 'blur(20px)'
            }}
          />

          {/* Tech border effect */}
          <div
            className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-all duration-500 ease-out"
            style={{
              border: `2px solid ${color}30`,
              background: `
                linear-gradient(90deg,
                  transparent 0%,
                  ${color}08 50%,
                  transparent 100%
                )
              `,
              clipPath: `
                polygon(
                  0 0,
                  100% 0,
                  100% calc(100% - 15px),
                  calc(100% - 15px) 100%,
                  0 100%
                )
              `
            }}
          />
        </div>

        {/* Back of card - Coming Soon */}
        {isShopCard && (
          <div 
            className="absolute inset-0 glass-panel p-6 rounded-xl border-2 overflow-hidden transition-all duration-500 ease-out backface-hidden backdrop-blur-md"
            style={{ 
              borderColor: `${color}40`,
              boxShadow: `
                0 0 20px ${color}20,
                inset 0 0 20px ${color}15,
                0 0 0 1px ${color}30
              `,
              background: `
                linear-gradient(135deg, 
                  ${color}10 0%, 
                  ${color}05 50%,
                  ${color}15 100%
                )
              `,
              transform: 'rotateY(180deg) translateZ(1px)',
              backfaceVisibility: 'hidden'
            }}
          >
            {/* Tech grid background */}
            <div 
              className="absolute inset-0 transition-all duration-500 ease-out opacity-30"
              style={{
                backgroundImage: `
                  linear-gradient(to right, ${color}20 1px, transparent 1px),
                  linear-gradient(to bottom, ${color}20 1px, transparent 1px)
                `,
                backgroundSize: '20px 20px',
                mask: 'radial-gradient(circle at center, black 60%, transparent)'
              }}
            />

            {/* Coming Soon Content */}
            <div className="relative z-10 h-full flex flex-col items-center justify-center text-center">
              <h3 
                className="text-4xl font-horizon tracking-wider mb-4"
                style={{ 
                  color: color,
                  filter: `drop-shadow(0 0 15px ${color}50)`,
                  opacity: 0.95
                }}
              >
                Coming Soon
              </h3>
              <p 
                className="text-white/60 text-sm"
                style={{
                  filter: `drop-shadow(0 0 10px ${color}30)`
                }}
              >
                Our drone shop is under construction
              </p>
            </div>

            {/* Tech border effect */}
            <div
              className="absolute inset-0"
              style={{
                border: `2px solid ${color}40`,
                background: `
                  linear-gradient(90deg,
                    transparent 0%,
                    ${color}10 50%,
                    transparent 100%
                  )
                `,
                clipPath: `
                  polygon(
                    0 0,
                    100% 0,
                    100% calc(100% - 15px),
                    calc(100% - 15px) 100%,
                    0 100%
                  )
                `
              }}
            />
          </div>
        )}
      </div>
    </motion.a>
  );
};

export const HubSection: React.FC = () => {
  const cardGroups = {
    products: [
      {
        title: "Agent Playground",
        description: "Experiment with drone AI in our simulator",
        color: "#00FFFF",
        icon: <FaRobot />,
        link: "/simulation"
      },
      {
        title: "Shop Drone",
        description: "Get your hands on our custom drone kits",
        color: "#FF5722",
        icon: <FaShoppingCart />,
        link: "/shop"
      }
    ],
    community: [
      {
        title: "Learn",
        description: "Tutorials and resources to get started",
        color: "#A020F0",
        icon: <FaGraduationCap />,
        link: "/learn"
      },
      {
        title: "Our Mission",
        description: "Discover what drives us forward",
        color: "#FFD700",
        icon: <FaHeart />,
        link: "/mission"
      }
    ],
    socials: [
      {
        title: "Twitter",
        description: "Follow us for the latest updates and announcements",
        color: "#FF00FF",
        icon: <FaXTwitter />,
        link: "https://twitter.com/thedroneforge"
      },
      {
        title: "Discord",
        description: "Join our community of drone enthusiasts",
        color: "#00FF94",
        icon: <FaDiscord />,
        link: "https://t.co/ZIRu0kAv2X"
      }
    ]
  };

  const SectionTitle: React.FC<{ title: string }> = ({ title }) => {
    return (
      <motion.div
        className="w-full text-center mb-16"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
      >
        <motion.div
          className="relative inline-block"
          whileHover={{ y: -2 }}
          transition={{ duration: 0.2 }}
        >
          {/* Title Container */}
          <div className="relative">
            {/* Accent Line */}
            <div 
              className="absolute -bottom-3 left-1/2 -translate-x-1/2 w-12 h-0.5 bg-[#00F135]"
              style={{
                boxShadow: "0 0 10px rgba(0,241,53,0.5)"
              }}
            />
            
            {/* Title Text */}
            <h3 
              className="text-3xl font-horizon tracking-wider text-white/90"
            >
              {title}
            </h3>
          </div>
        </motion.div>
      </motion.div>
    );
  };

  return (
    <section className="relative w-full min-h-screen bg-black flex flex-col justify-center items-center py-20">
      {/* Background effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-black" />
        <div className="absolute inset-0 tech-grid opacity-20" />
      </div>

      {/* Content */}
      <div className="relative z-10 container mx-auto px-4">
        {/* Title */}
        <div className="text-center mb-16">
          <motion.h2 
            className="text-4xl sm:text-5xl md:text-6xl font-horizon text-white mb-4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            EXPLORE THE
            <motion.span 
              className="text-[#00F135]"
              animate={{ 
                textShadow: [
                  "0 0 20px rgba(0,241,53,0)",
                  "0 0 30px rgba(0,241,53,0.2)",
                  "0 0 20px rgba(0,241,53,0)"
                ]
              }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              {" "}FORGE
            </motion.span>
          </motion.h2>
          <motion.p
            className="text-white/60 text-lg max-w-2xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
          >
            Your gateway to everything Droneforge has to offer
          </motion.p>
          <motion.div 
            className="mt-8"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3 }}
          >
            <DroneforgeButton
              variant="accent"
              size="lg"
              href="/simulation"
              className="px-12"
            >
              Start Building Now
            </DroneforgeButton>
          </motion.div>
        </div>

        {/* Cards Grid with Sections */}
        <div className="space-y-16 max-w-7xl mx-auto">
          {/* Products Section */}
          <div>
            <SectionTitle title="PRODUCTS" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {cardGroups.products.map((card, index) => (
                <motion.div
                  key={card.title}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                >
                  <HubCard {...card} />
                </motion.div>
              ))}
            </div>
          </div>

          {/* Community Section */}
          <div>
            <SectionTitle title="COMMUNITY" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {cardGroups.community.map((card, index) => (
                <motion.div
                  key={card.title}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                >
                  <HubCard {...card} />
                </motion.div>
              ))}
            </div>
          </div>

          {/* Socials Section */}
          <div>
            <SectionTitle title="SOCIALS" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {cardGroups.socials.map((card, index) => (
                <motion.div
                  key={card.title}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                >
                  <HubCard {...card} />
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}; 