import React from 'react';
import { motion } from 'framer-motion';
import { FaBook, FaCode, FaRobot, FaGraduationCap } from 'react-icons/fa';
import { Navbar } from './Navbar.tsx';

const LearnSection: React.FC<{ title: string; description: string; icon: React.ReactNode }> = ({ title, description, icon }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    className="glass-panel p-8 rounded-xl border border-[#00F135]/10 hover:border-[#00F135]/30 transition-all duration-300"
  >
    <div className="flex items-start gap-6">
      <div className="text-[#00F135] text-3xl">
        {icon}
      </div>
      <div>
        <h3 className="text-xl font-horizon text-white mb-2">{title}</h3>
        <p className="text-white/60 text-sm leading-relaxed">{description}</p>
      </div>
    </div>
  </motion.div>
);

const Learn: React.FC = () => {
  const sections = [
    {
      title: "Getting Started",
      description: "Begin your journey into drone development with our comprehensive introduction to the DroneFORGE platform.",
      icon: <FaBook />
    },
    {
      title: "Core Concepts",
      description: "Master the fundamental concepts of drone programming, control systems, and autonomous navigation.",
      icon: <FaCode />
    },
    {
      title: "AI Development",
      description: "Learn how to create and train intelligent drone agents using our advanced AI development tools.",
      icon: <FaRobot />
    },
    {
      title: "Advanced Topics",
      description: "Dive deep into advanced drone programming topics, optimization techniques, and real-world applications.",
      icon: <FaGraduationCap />
    }
  ];

  return (
    <>
      <Navbar show={true} />
      <div className="min-h-screen bg-black text-white pt-24">
        {/* Background effects */}
        <div className="fixed inset-0 -z-10">
          <div className="absolute inset-0 bg-black" />
          <div className="absolute inset-0 tech-grid opacity-20" />
          <div className="absolute inset-0 hero-gradient opacity-30" />
        </div>

        <div className="container mx-auto px-4 py-12">
          {/* Header */}
          <div className="text-center mb-16">
            <motion.h1 
              className="text-4xl sm:text-5xl md:text-6xl font-horizon mb-4"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
            >
              LEARN TO
              <span className="bg-gradient-to-r from-[#00F135] to-[#00F135]/50 text-transparent bg-clip-text">
                {" "}BUILD
              </span>
            </motion.h1>
            <motion.p
              className="text-white/60 text-lg max-w-2xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              Master drone development with our comprehensive learning resources
            </motion.p>
          </div>

          {/* Content Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-6xl mx-auto">
            {sections.map((section, index) => (
              <motion.div
                key={section.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <LearnSection {...section} />
              </motion.div>
            ))}
          </div>

          {/* Coming Soon Banner */}
          <motion.div
            className="mt-16 text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            <div className="inline-block glass-panel px-6 py-3 rounded-full border border-[#00F135]/20">
              <p className="text-[#00F135] font-mono">
                Full documentation coming soon...
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Learn; 